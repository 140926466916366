<template>
  <div class="project-sales-banker-info p-2">
    <h4 class="mb-3">Banker</h4>
    <div
      v-for="bankLoan in bankLoans"
      :key="bankLoan.id"
      class="row bordered-card bg-white p-2 mb-1"
    >
      <div class="col-12 sm-col-8">
        <p class="font-bold">{{ bankLoan.banker.name }}</p>
        <p>{{ bankLoan.banker.bank }}</p>
        <p>{{ bankLoan.banker.user.email }}</p>
        <div
          v-if="selectedBanker && bankLoan.banker.id == selectedBanker.id"
          class="banker-info__selected mt-1"
        >
          <i class="fas fa-check"></i>
          Selected
        </div>
      </div>
      <div class="col-12 sm-col-4 d-flex justify-content-end">
        <lead-status :status="bankLoan.status"></lead-status>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    LeadStatus: () =>
      import("@/components/GlobalComponents/Shared/Banker/LeadStatus")
  },
  mixins: [],
  props: {
    bankLoans: {
      type: Array,
      default: () => []
    },
    selectedBanker: {
      type: [String, Number],
      default: null
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-sales-banker-info {
  .banker-info__selected {
    background: $color-accent2;
    border-radius: 5px;
    color: white;
    padding: 4px 8px;
    width: fit-content;
  }
}
</style>
